var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table',{attrs:{"items":_vm.items,"id":"table-list","responsive":"","per-page":_vm.perPage,"current-page":_vm.currentPage,"fields":_vm.fields,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"busy":_vm.loading,"show-empty":"","bordered":"","striped":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-2"}),_c('strong',[_vm._v("Chargement...")])],1)]},proxy:true},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-gray"},[_vm._v("Table vide")])]},proxy:true},{key:"cell(inscriptions)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_c('b-badge',{staticStyle:{"cursor":"pointer","background-color":"#adb5bd","border-color":"#adb5bd"},attrs:{"pill":""},on:{"click":function($event){return _vm.$emit('showMember', data.item.inscriptions, data.item.date)}}},[_vm._v(_vm._s(data.value.length)+" ")])],1)]}},{key:"cell(comite)",fn:function(data){return [_c('b-link',{attrs:{"to":{
          name: 'show-committee',
          params: {
            slug: data.item.comiteslug || 'abcde',
            id: data.item.comiteid,
          },
        }}},[_vm._v(_vm._s(data.value))])]}},{key:"cell(date)",fn:function(data){return [_c('b-link',{attrs:{"to":{
          name: 'show-session',
          params: {
            slug: data.item.slug || 'abcde',
            id: data.item.id,
          },
        }}},[_vm._v(_vm._s(data.value))])]}},{key:"cell(name)",fn:function(data){return [_c('b-link',{attrs:{"to":{
          name: 'show-session',
          params: {
            slug: data.item.slug || 'abcde',
            id: data.item.id,
          },
        }}},[_vm._v(_vm._s(data.value))])]}},{key:"cell(Planning)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.planning.name))])]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"container ml-3 justify-content-end"},[(data.value.includes('delete'))?_c('b-button',{staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"width":"40px","height":"40px"},attrs:{"variant":"light","pill":"","size":"sm"},on:{"click":function($event){return _vm.$emit('deleteItemSession', data.item.id)}}},[_c('i',{staticClass:"mdi mdi-delete"})]):_vm._e()],1)]}}])}),_c('b-pagination',{attrs:{"per-page":_vm.perPage,"total-rows":_vm.rows,"align":"right","aria-controls":"table-list"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }