<template>
  <div>
    <b-table
      :items="items"
      id="table-list"
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :busy="loading"
      show-empty
      bordered
      striped
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>

      <template v-slot:cell(inscriptions)="data">
        <div class="text-center">
          <b-badge
            pill
            @click="$emit('showMember', data.item.inscriptions, data.item.date)"
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
            >{{ data.value.length }}
          </b-badge>
        </div>
      </template>
      <!-- <template v-slot:cell(date)="data">
        <span
          class="text-white text-center px-2 py-1 rounded"
          :style="{ backgroundColor: dateBackgroundColor(data.item.date) }"
          >{{ data.value }}</span
        >
      </template> -->
      <template v-slot:cell(comite)="data">
        <b-link
          :to="{
            name: 'show-committee',
            params: {
              slug: data.item.comiteslug || 'abcde',
              id: data.item.comiteid,
            },
          }"
          >{{ data.value }}</b-link
        >
      </template>
      <template v-slot:cell(date)="data">
        <b-link
          :to="{
            name: 'show-session',
            params: {
              slug: data.item.slug || 'abcde',
              id: data.item.id,
            },
          }"
          >{{ data.value }}</b-link
        >
      </template>
      <template v-slot:cell(name)="data">
        <b-link
          :to="{
            name: 'show-session',
            params: {
              slug: data.item.slug || 'abcde',
              id: data.item.id,
            },
          }"
          >{{ data.value }}</b-link
        >
      </template>
      <template v-slot:cell(Planning)="data">
        <span>{{ data.item.planning.name }}</span>
      </template>
      <template v-slot:cell(actions)="data">
        <div class="container ml-3 justify-content-end">
          <!-- <b-button
            v-if="data.value.includes('show')"
            @click="
              $router.push({
                name: 'show-session',
                params: {
                  slug: data.item.slug || 'abcde',
                  id: data.item.id,
                },
              })
            "
            variant="light"
            pill
            size="sm"
            style="width: 40px; height: 40px"
            class="d-flex align-items-center justify-content-center"
            ><i class="mdi mdi-eye"></i
          ></b-button> -->
          <b-button
            v-if="data.value.includes('delete')"
            @click="$emit('deleteItemSession', data.item.id)"
            variant="light"
            pill
            size="sm"
            style="width: 40px; height: 40px"
            class="d-flex align-items-center justify-content-center"
            ><i class="mdi mdi-delete"></i
          ></b-button>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :per-page="perPage"
      :total-rows="rows"
      align="right"
      aria-controls="table-list"
    >
    </b-pagination>
  </div>
</template>

<script>
//import TableActions from "@/components/custom/TableActions.vue";
import moment from "moment";

export default {
  components: {},
  data: () => ({
    sortBy: "name",
    perPage: 10,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    legalEntity: {
      tableFields: [
        { key: "session_name", label: "Session", sortable: true },
        { key: "session_date", label: "Date", sortable: true },
        { key: "committee", label: "Comité", sortable: true },
        { key: "planning", label: "Planning", sortable: true },
        {
          key: "inscriptions",
          label: "Nombre d'inscrits",
          sortable: true,
          thStyle: { textAlign: "center" },
        },
        { key: "actions", label: "", sortable: false },
      ],
      tableItems: [],
    },
  }),
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    date(value) {
      return moment(value, "DD/MM/YYYY").format("DD/MM/YYYY");
    },

    dateBackgroundColor: function (date) {
      // "#38b000" : "#d20f35";
      moment(date);
      var diff = parseInt(
        moment
          .duration(
            moment(date, "DD/MM/YYYY").diff(moment(new Date(), "DD/MM/YYYY"))
          )
          .asDays()
      );
      return diff < 0 ? "#d20f35" : diff > 4 ? "#38b000" : "#ffd60a";
    },
  },
  props: {
    fields: Array,
    items: Array,
    loading: Boolean,
  },
};
</script>

<style>
card .card-body {
  padding: 2.5rem 2.5rem !important;
}
</style>
