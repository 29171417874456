<template>
  <div class="row">
    <div class="col-10" style="margin-top: -56px">
      <span style="color: #072648 !important ; font-family: ubuntu-light, sans-serif"
        ><i :class="$route.meta.iconClass"></i> Session :
      </span>
      <span style="font-size: 22px">{{ items.name }}</span>
    </div>

    <div >
    </div>
    <div class="col-12" style="margin-top: -20px">
      <h4 class="card-title mt-4 mb-1">Liste des Inscriptions :</h4>
      <hr  />

    </div>



    <div class="col-12">
      <b-table :items="items.members" :fields="members.tableFields"
               :per-page="perPage"
               :current-page="currentPage"
               :sort-by.sync="sortBy"
               :sort-desc.sync="sortDesc"
               bordered striped>
        <template v-slot:cell(user)="data">
          <TableResponsable :responsable="data.value" />
        </template>
        <template v-slot:cell(prenom)="data">
          <div>
            {{ data.item.user.firstname }}
          </div>
        </template>

        <template v-slot:cell(nom)="data">
          <div>
            {{ data.item.user.lastname }}
          </div>
        </template>

        <template v-slot:cell(role)="data">
          <div>
            {{ data.item.role }}
          </div>
        </template>
      </b-table>
      <br>
      <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
      >
      </b-pagination>
    </div>
  </div>
</template>

<script>
//import {mapGetters} from "vuex";
import TableResponsable from "@/components/custom/TableResponsable.vue";

export default {
  components: {
    TableResponsable,
  },
  props: {
    date :String,
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    sortBy: "user",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    members: {
      tableItems: [],
      tableFields: [
        { key: "user", label: "user", sortable: true },
        { key: "prenom", label: "Prénom", sortable: true },
        { key: "nom", label: "Nom", sortable: true },
      ],
    },
  }),
  computed:{
    rows() {
      return this.items.members.length;
    },
  }
};
</script>

<style scoped></style>
