<template>
  <div>
    <section class="section position-relative popUpFather">
      <div class="row">
        <div class="col-12">
          <div class="bg-white p-2">
            <b-tabs
              :value="$store.state.tabs.activeTab"
              @changed="activeTab = $store.state.tabs.activeTab"
              @activate-tab="
                (newTabIndex) => $store.dispatch('tabs/changeTab', newTabIndex)
              "
              class="tab-solid tab-solid-primary mt-3"
            >
              <b-tab title="Session">
                <div class="row">
                  <div class="col-12 col-sm-3 my-3">
                    <div class="my-auto mr-2">
                      <h4 class="card-title my-auto">Liste des sessions</h4>
                    </div>
                  </div>
                  <div
                    class="col-12 col-sm-9 d-flex flex-wrap justify-content-end"
                  >
                    <div
                      class="pl-1 pr-0 col-md-auto col-6 add"
                      style="width: 20%"
                    >
                      <v-select
                        placeholder="Comité"
                        label="text"
                        :reduce="(comite) => comite.value"
                        :options="comitesList"
                        v-model="searchQuery.comite"
                      />
                    </div>
                    <div
                      class="pl-1 pr-0 col-md-auto col-6 add"
                      style="width: 20%"
                    >
                      <date-picker
                        placeholder="Session"
                        v-model="searchQuery.session"
                        format="DD/MM/YYYY"
                      />
                    </div>
                    <div
                      class="pl-1 pr-0 col-md-auto col-6 add"
                      style="width: 20%"
                    >
                      <v-select
                        placeholder="Statut"
                        :options="[
                          'Actif',
                          'Inscription close',
                          'Décision',
                          'Annulé',
                        ]"
                        v-model="searchQuery.status"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <hr class="mt-1 mb-3 bg-secondary" />
                  </div>
                  <div class="col-12">
                    <!-- {{ COMITES.map(({ planning }) => planning) }} -->
                    <session-table
                      @editItem="$router.push({ name: 'edit-session' })"
                      @showItem="
                        (vi_item) =>
                          $router.push({
                            name: `show-session`,
                            params: { vi_item },
                          })
                      "
                      @deleteItemSession="deleteItemSession"
                      @showGeoMap="showGeoMap = !showGeoMap"
                      @showMember="showMember"
                      :loading="loadingData"
                      :items="sessionList"
                      :fields="sessions.tableFields"
                    />
                  </div>
                </div>
              </b-tab>
              <!--              <b-tab title="Inscription">-->
              <!--                <div class="row">-->
              <!--                  <div class="col-12 col-sm-3 my-3">-->
              <!--                    <div class="my-auto mr-2">-->
              <!--                      <h4 class="card-title my-auto">Liste des sessions</h4>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                  <div class="col-12 col-sm-9 d-flex flex-wrap justify-content-end">-->
              <!--                    <button-->
              <!--                      type="button"-->
              <!--                      class="btn h1 mb-2 test-btn btn-secondary d-flex align-items-center align-btn-table-insc"-->
              <!--                      @click="$router.push({ name: 'add-inscription' })"-->
              <!--                      style="-->
              <!--                        background-color: rgb(0, 123, 255) !important;-->
              <!--                        border-color: rgb(0, 123, 255) !important;-->
              <!--                      "-->
              <!--                    >-->
              <!--                      <i class="mdi mdi-plus mr-2"></i>-->
              <!--                      Nouvelle Inscription-->
              <!--                    </button>-->
              <!--                  </div>-->
              <!--                  <div class="col-12">-->
              <!--                    <hr class="mt-1 mb-3 bg-secondary" />-->
              <!--                  </div>-->
              <!--                  <div class="col-12">-->
              <!--                    <inscription-table-->
              <!--                      @showItem="-->
              <!--                        (vi_item) =>-->
              <!--                          $router.push({ name: `show-inscription`, params: { vi_item } })-->
              <!--                      "-->
              <!--                      @editItem="$router.push({ name: 'edit-inscription' })"-->
              <!--                      @deleteItemInscription="deleteItemInscription"-->
              <!--                      @showGeoMap="showGeoMap = !showGeoMap"-->
              <!--                      class="bg-white p-2"-->
              <!--                      @showInvite="showinvite"-->
              <!--                      :loading="loadingData"-->
              <!--                      :items="inscriptionList"-->
              <!--                      :fields="inscriptions.tableFields"-->
              <!--                    />-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </b-tab>-->
            </b-tabs>
          </div>
        </div>
      </div>
    </section>
    <div v-if="showMembersList" to="modals-xyz-548">
      <Modal @close="showMembersList = !showMembersList">
        <Showlist :items="selectedMembers" />
      </Modal>
    </div>
    <div v-if="showinviteList" to="modals-xyz-548">
      <Modalinvite @close="showinviteList = !showinviteList">
        <invite-list :items="selectedInvite" />
      </Modalinvite>
    </div>
  </div>
</template>

<script>
import SessionTable from "./components/SessionTable.vue";
import Showlist from "./components/Showlist.vue";
import { mapGetters } from "vuex";
import Modal from "@/components/custom/Modal";
import Modalinvite from "@/components/custom/ModalMembers";
import Swal from "sweetalert2";
import moment from "moment";
import InviteList from "./components/InviteList";

export default {
  components: {
    InviteList,
    Modalinvite,
    SessionTable,
    Modal,
    Showlist,
  },
  data: () => ({
    showMembersList: false,
    showinviteList: false,
    showVisionModal: false,
    filterComite: false,
    filterSession: false,
    filterStatut: false,
    selectedInvite: null,
    selectedMembers: [],
    selectedItem: [],
    filterMyTeamTask: false,
    tableItemsInscription: [],
    showGeoMap: false,
    loadingData: true,
    firstname: Array(),
    sessions: {
      tableItems: [],
      tableFields: [
        { key: "comite", label: "Comité", sortable: true },
        { key: "date", label: "Session", sortable: true },
        { key: "status", label: "Statut session", sortable: true },
        { key: "inscriptions", label: "Nombre d'inscrits", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
    },

    inscriptions: {
      tableItems: [],
      tableFields: [
        { key: "user", label: "Présentateur", sortable: true },
        { key: "sessionn", label: "Session", sortable: true },
        { key: "dossier", label: "Dossier", sortable: true },
        { key: "invite", label: "Invités", sortable: true },
        { key: "date", label: "Date", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
    },
    searchQuery: {
      comite: null,
      session: null,
      status: null,
    },
  }),
  created() {
    this.$store.dispatch("manage/fetchAllSessions").then(() => {
      this.loadingData = false;
    });
    this.$store.dispatch("manage/fetchAllInscriptions").then(() => {
      this.loadingData = false;
    });
    this.$store.dispatch("comite/fetchAllComites").then(() => {
      this.loadingData = false;
    });
  },
  computed: {
    ...mapGetters("manage", ["INSCRIPTIONS"]),
    ...mapGetters("manage", ["SESSIONS"]),
    ...mapGetters("comite", ["COMITES"]),
    ...mapGetters(["AuthenticatedUser", "isArchitect"]),
    filteredFdrs: function () {
      if (this.tableItems.length) {
        return this.tableItems.filter((fdr) => fdr.id == 1);
      }
      return [];
    },
    sessionList() {
      // filter sessions by AuthenticatedUser role
      // this.SESSIONS.filter((session) =>
      //   this.isArchitect
      //     ? session.inscriptions
      //       ? session.inscriptions.find((inscription) =>
      //           inscription.user && this.AuthenticatedUser
      //             ? inscription.user.id === this.AuthenticatedUser.id
      //             : false
      //         )
      //       : true
      //     : false
      // )
      return this.SESSIONS.filter(
        (item) =>
          (this.searchQuery.comite
            ? item.planning.comite
              ? item.planning.comite["@id"] === this.searchQuery.comite
              : false
            : true) &&
          (this.searchQuery.status
            ? item.status === this.searchQuery.status
            : true) &&
          (this.searchQuery.session
            ? moment(item.date, "DD/MM/YYYY").format("DD/MM/YYYY") ===
              moment(this.searchQuery.session, "DD/MM/YYYY").format(
                "DD/MM/YYYY"
              )
            : true)
      ).map((item) => ({
        ...item,
        planning: item.planning ? item.planning.name : "-",
        comite: item.planning.comite ? item.planning.comite.name : "-",
        comiteid: item.planning.comite ? item.planning.comite.id : "-",
        comiteslug: item.planning.comite ? item.planning.comite.slug : "-",
        date: moment(item.date, "DD/MM/YYYY").format("DD/MM/YYYY"),
        actions: ["show", "delete"],
      }));
    },
    inscriptionList() {
      return this.tableItemsInscription;
    },

    comitesList() {
      return this.COMITES.map((comite) => ({
        text: comite.name,
        value: comite["@id"],
      }));
    },
  },

  methods: {
    Comite() {
      this.filterComite = !this.filterComite;
    },
    Session() {
      this.filterSession = !this.filterSession;
    },
    Statut() {
      this.filterStatut = !this.filterStatut;
    },

    showMember(members, name) {
      if (members.length > 0) {
        members = {
          members: members,
          name: name,
        };
        this.selectedMembers = members;
        this.showMembersList = true;
      }
    },
    showinvite(invite) {
      if (invite.length > 0) {
        this.selectedInvite = invite;
        this.showinviteList = true;
      }
    },
    showItem(item) {
      this.selectedItem = item;
      this.showVisionModal = true;
    },
    deleteItemSession: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("manage/deletesession", id).then(() => {
            this.$store.dispatch("manage/fetchAllSessions").then(() => {
              Swal.fire("Supprimé!", "", "success");
            });
          });
        }
      });
    },
    deleteItemInscription: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.tableItemsInscription = this.tableItemsInscription.filter(
            (item) => item.id !== id
          );
          this.$store.dispatch("manage/deleteinscription", id).then(() => {
            this.$store.dispatch("manage/fetchAllSessions");
            Swal.fire("Supprimé!", "", "success");
          });
        }
      });
    },
  },

  watch: {
    INSCRIPTIONS: function () {
      this.tableItemsInscription = this.INSCRIPTIONS.map((item) => ({
        ...item,
        date: moment(item.date, "DD/MM/YYYY").format("DD/MM/YYYY"),
        sessionn: item.session ? item.session.name : "-",
        dossier: item.dossier ? item.dossier.name : "",

        actions: ["show", "delete"],
      }));
    },
  },
};
</script>

<style></style>
